.commercial-container {
  width: 100%;
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", Sans-serif;
  @media (max-width: 768px) {
    align-items: normal;
  }
  .commercial-title {
    margin-top: 10px;
    color: #05173d;
    font-family: "Inter", Sans-serif;
    font-size: 42px;
    font-weight: 500;
    text-transform: capitalize;
    font-style: normal;
    text-decoration: none;
    line-height: 44px;
    letter-spacing: 1px;
    @media (max-width: 768px) {
      text-align: center;
      margin-bottom: -40px;
    }
  }
  .commercial-subtitle {
    font-size: 35px;
    padding: 15px 0 15px 0;
    font-weight: 600;
    color: #3c3c3c;
    @media (max-width: 768px) {
      font-size: 20px;
      text-align: center;
    }
  }
  .commercial-card-container {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .commercial-card {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 30px;
      margin-right: 30px;
      @media (max-width: 768px) {
        margin: 0;
      }
      .card {
        width: 370px;
        background-color: white;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
        border-radius: 10px 10px 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: 768px) {
          width: 95%;
          margin-bottom: 30px;
        }
        .card-image {
          border-radius: 10px 10px 0px 0px;
          width: 100%;
          height: auto;
        }
        .slick-dots {
          bottom: 15px;
        }
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
        }
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .content-card {
          display: flex;
          align-items: center;
          flex-direction: column;
          .card-title {
            font-family: "Poppins", sans-serif;
            font-size: 26px;
            font-weight: 600;
            margin-top: 8px;
          }
          .card-price {
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-weight: 300;
            padding: 0px;
            .judul-pt {
              font-size: 16px;
              padding: 0px;
              font-weight: 500;
            }
            .ket-pt {
              font-size: 16px;
              padding: 0px;
              font-weight: 500;
            }
            .pricetotal {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 45px;
              font-weight: 500;
              padding: 0px;
              margin: -15px;
            }
          }
          .card-dp {
            background-color: #467ff7;
            color: white;
            font-family: "Poppins", sans-serif;
            width: 100%;
            font-size: 32px;
            font-weight: 800;
            padding: 5px 0px 5px 0;
            display: flex;
            justify-content: center;
          }
          .card-icon {
            color: #3c3c3c;
            font-size: 18px;
            padding: 15px 0px 15px 0;
            span {
              margin: 0px 5px 0px 5px;
            }
          }
          .card-button {
            padding: 10px 0px 15px 0;
            .button {
              font-family: "Poppins", Sans-serif;
              font-size: 16px;
              font-weight: 400;
              text-transform: capitalize;
              font-style: normal;
              text-decoration: none;
              line-height: 1em;
              letter-spacing: 0px;
              fill: #ffffff;
              color: #ffffff;
              background-color: #467ff7;
              border-style: solid;
              border-width: 1px 1px 1px 1px;
              border-color: #467ff7;
              border-radius: 15px 15px 15px 15px;
              padding: 16px 85px 16px 85px;
              cursor: pointer;
              transition: background-color 0.3s ease, color 0.3s ease;
              &:hover {
                background-color: white;
                color: black;
              }
            }
          }
        }
      }
    }
  }
}
