.lokasi-container {
  margin-top: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .lokasi-inner {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .lokasi-title {
      font-size: 60px;
      font-family: "Roboto", Sans-serif;
      padding: 15px 0px 25px 0px;
      letter-spacing: 10px;
      color: #3c3c3c;
      font-weight: 600;
      text-align: center;
    }
    .lokasi-map {
      width: 1200px;
      @media (max-width: 768px) {
        width: 350px;
      }
      .image-map {
        width: 100%;
        @media (max-width: 768px) {
          height: 250px;
        }
      }
    }
    .lokasi-list {
      font-size: 20px;
      width: 80%;
      text-align: left;
      @media (max-width: 768px) {
        width: 140%;
        font-size: 18px;
      }
    }
  }
}
