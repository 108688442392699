.navbarcontainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 80px;
  z-index: 10;
  @media (max-width: 768px) {
    position: relative;
  }
  .navbar {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.47);
    @media (max-width: 768px) {
      width: 100%;
      border-radius: 0;
    }
    .navbarlogo {
      width: 40%;
      text-align: center;
      font-size: 25px;
      font-weight: 400;
      letter-spacing: 3px;
      font-family: "Poppins", sans-serif;
      color: #1c244b;
    }
    .navbaritem {
      width: 80%;
      .item {
        width: 100%;
        margin-left: 150px;
        display: flex;
        list-style: none;
        color: black;
        font-family: "Poppins", sans-serif;
        @media (max-width: 768px) {
          display: none;
        }
        &.active {
          display: flex; /* Show navbar links on mobile menu open */
          flex-direction: column;
          align-items: center;
          right: 0;
          gap: 10px;
          color: black;
          margin-top: 25px;
          position: absolute; /* Position for overlay effect */
          top: 70%; /* Position below navbar */
          width: 100%; /* Account for navbar logo width */
          animation: showNavbarOverlay 0.3s ease-in-out forwards;
          background-color: #bdbdbda0;
          z-index: 1000; /* Ensure menu is on top */
          .divider {
            padding-top: 50px;
          }
          .divider2 {
            padding-bottom: 50px;
          }
        }
        li {
          margin: 10px;
          padding: 5px;
          &:hover {
            background-color: rgba(63, 185, 255, 0.393);
            border-radius: 10px;
            cursor: pointer;
          }
          a {
            color: rgb(5, 5, 5);
            text-decoration: none;
          }
        }
      }
    }
    .burger-button {
      z-index: 9999;
      background-color: transparent;
      display: none;
      border: none;
      padding: 0;
      margin-right: 25px;
      cursor: pointer;
      &.active {
        /* Add styles for active (clicked) state */
        /* Example: rotate bars */
        .bar:nth-child(1) {
          transform: translateY(8px) rotate(45deg);
        }
        .bar:nth-child(2) {
          opacity: 0;
        }
        .bar:nth-child(3) {
          transform: translateY(-8px) rotate(-45deg);
        }
      }
      @media (max-width: 768px) {
        display: block;
      }
      .bar {
        display: block;
        width: 25px;
        height: 3px;
        background-color: rgb(33, 33, 33);
        margin-bottom: 5px;
      }
    }
  }
}
