.grand-container {
  padding-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f3f5f8;

  .grand-center {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
      width: 90%;
    }
    .grand-title {
      @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400&display=swap");
      font-family: "Poppins", sans-serif;
      color: #324a6d;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      font-style: normal;
      letter-spacing: 10px;
      @media (max-width: 768px) {
        text-align: center;
        font-size: 24px;
        padding-bottom: 20px;
      }
    }
    .grand-subtitle {
      @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 60px;
      color: #1c244b;
      padding-bottom: 10px;
      @media (max-width: 768px) {
        text-align: center;
        font-size: 40px;
        line-height: 1em;
      }
    }
    .grand-description {
      text-align: center;
      @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400&display=swap");
      color: #324a6d;
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      font-weight: 300;
    }
    .grand-button {
      padding: 40px;
      @media (max-width: 768px) {
        padding: 20px;
      }
      .button {
        font-family: "Poppins", Sans-serif;
        font-size: 16px;
        font-weight: 400;
        text-transform: capitalize;
        font-style: normal;
        text-decoration: none;
        line-height: 1em;
        letter-spacing: 0px;
        fill: #ffffff;
        color: #ffffff;
        background-color: #467ff7;
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #467ff7;
        border-radius: 100px 100px 100px 100px;
        padding: 16px 55px 16px 55px;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
        &:hover {
          background-color: white;
          color: black;
        }
      }
    }
  }
}
