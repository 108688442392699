.cluster-container {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
  .cluster-title {
    padding: 25px 0 30px 0;
    color: #3c3c3c;
    font-family: "Roboto", Sans-serif;
    font-weight: 600;
    font-size: 30px;
    @media (max-width: 768px) {
      font-size: 40px;
      text-align: center;
      margin-bottom: -50px;
    }
  }
  .cluster-card-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .cluster-card {
      background-color: white;
      margin-right: 30px;
      margin-left: 30px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
      border-radius: 10px 10px 10px 10px;
      width: 370px;
      justify-content: center;
      @media (max-width: 768px) {
        width: 95%;
        margin-top: 50px;
        margin-left: 15px;
        margin-right: 15px;
      }
      .slick-dots {
        bottom: 15px;
      }
      .slick-next {
        right: 25px;
      }
      .slick-prev {
        z-index: 1;
        left: 25px;
      }
      .slick-next::before,
      .slick-prev::before {
        font-family: "slick";
        font-size: 30px;
      }
      .cluster-image {
        width: 100%;
        height: auto;
        border-radius: 10px 10px 0px 0px;
      }
      .cluster-card-title {
        font-family: "Roboto", Sans-serif;
        display: flex;
        width: 100%;
        flex-direction: row;
        padding: 35px 0 5px 0;
        .cluster-name {
          text-align: center;
          justify-content: center;
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 100%;
          .cluster-name-sub {
            font-size: 22px;
            font-weight: 600;
          }
          //.cluster-name-sub2 {
          //}
        }
        .cluster-price {
          padding-bottom: 10px;
          justify-content: center;
          flex-direction: column;
          display: flex;
          align-items: center;
          background-color: #467ff7;
          border-radius: 10px 0px 0px 10px;
          text-align: center;
          color: #ffffff;
          width: 100%;
          .cluster-price-sub {
            font-size: 50px;
            font-weight: 600;
          }
          //.cluster-price-sub2 {
          //}
        }
      }
      .card-separator {
        height: 0.1px;
        margin-top: 15px;
      }
      .cluster-icon {
        padding: 15px 0px 15px 0;
        display: flex;
        font-size: 18px;
        justify-content: center;
        // span {
        // }
      }
      .cluster-button {
        padding-bottom: 15px;
        text-align: center;
        .cluster-whatsapp {
          font-family: "Poppins", Sans-serif;
          font-size: 16px;
          font-weight: 400;
          text-transform: capitalize;
          font-style: normal;
          text-decoration: none;
          line-height: 1em;
          letter-spacing: 0px;
          fill: #ffffff;
          color: #ffffff;
          background-color: #467ff7;
          border-style: solid;
          border-width: 1px 1px 1px 1px;
          border-color: #467ff7;
          border-radius: 15px 15px 15px 15px;
          padding: 16px 85px 16px 85px;
          cursor: pointer;
          transition: background-color 0.3s ease, color 0.3s ease;
          &:hover {
            background-color: white;
            color: black;
          }
        }
      }
    }
  }
}
