.containerPenawaran {
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400&display=swap");
  font-family: "Poppins", sans-serif;
  margin-top: 65px;
  padding-top: 40px;
  padding-bottom: 40px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f3f5f8;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 0;
  }
  .containergambar {
    //order: 2;
    width: 60%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      margin-top: 25px;
      order: 1;
      width: 100%;
      text-align: center;
    }
    .carousellogo {
      width: 70%;
      @media (max-width: 768px) {
        width: 95%;
      }
      img {
        width: 100%;
        border-radius: 15px;
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.395);
      }
    }
  }

  .containercontent {
    order: 1;
    width: 50%;
    // margin-left: 30px;
    text-align: center;
    @media (max-width: 768px) {
      margin-left: 25px;
      width: 100%;
      order: 2;
    }

    .penawarantitle2 {
      width: 500px;
      font-size: 50px;
      font-weight: 600;
      padding-bottom: 25px;
      @media (max-width: 768px) {
        width: 100%;
        font-size: 28px;
        font-weight: lighter;
      }
    }
    .disclaimer {
      margin-top: 25px;
      font-size: 13px;
    }
    .pointpenawaran {
      flex-direction: row;
      font-size: 20px;
      @media (max-width: 768px) {
        font-size: 22px;
      }
      .penawaranpoin {
        color: orange;
      }
    }
    .buttonpenawaran {
      margin-top: 25px;
      font-size: 16px;
      font-weight: 400;
      text-transform: capitalize;
      font-style: normal;
      text-decoration: none;
      line-height: 1em;
      letter-spacing: 0px;
      fill: #ffffff;
      color: #ffffff;
      background-color: #467ff7;
      border-style: solid;
      border-width: 1px 1px 1px 1px;
      border-color: #467ff7;
      border-radius: 100px 100px 100px 100px;
      padding: 16px 55px 16px 55px;
      display: inline-block;
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease;
      @media (max-width: 768px) {
        margin-left: 50px;
      }

      &:hover {
        background-color: white;
        color: black;
      }
    }
  }
}
