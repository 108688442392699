.footer-container {
  margin-top: 80px;
  width: 100%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);

  .footer-closing {
    padding-top: 20px;
    @import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .footer-title {
      color: #919896;
      font-size: 35px;
      font-family: "Playfair Display", sans-serif;
      font-weight: 700;
      letter-spacing: 3.8px;
      @media (max-width: 768px) {
        font-size: 30px;
      }
    }
    .footer-subtitle {
      color: #ba765c;
      font-family: "Poppins", Sans-serif;
      font-size: 55px;
      font-weight: 600;
      letter-spacing: 1.8px;
      @media (max-width: 768px) {
        font-size: 40px;
      }
    }
    .footer-alamat {
      color: #3c3c3c;
      font-family: "Poppins", Sans-serif;
      font-size: 14px;
      font-weight: 300;
      @media (max-width: 768px) {
        text-align: center;
      }
    }
    .footer-contact {
      color: #3c3c3c;
      font-family: "Poppins", Sans-serif;
      font-size: 14px;
      font-weight: 300;
    }
  }
  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ba765c;
    justify-content: center;
    align-items: center;
    .footer-subtitle2 {
      color: white;
      padding-top: 15px;
      font-size: 18px;
      font-family: "Playfair Display", sans-serif;
      font-weight: 700;
    }
    hr {
      margin-top: 15px;
      @media (max-width: 768px) {
        margin-bottom: -40px;
      }
    }
    .sub {
      color: #9c644e;
      padding-top: 15px;
      font-size: 18px;
      font-family: "Playfair Display", sans-serif;
      font-weight: 700;
    }
  }
}
