.comingsoon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .container-cs {
    margin-top: 3%;
    width: 80%;
    height: 55vh;
    background-image: url("./hero\ \(2\).webp");
    background-size: cover;
    border-radius: 10px;
    box-shadow: 0 0 10px 0;
    @media (max-width: 768px) {
      width: 98%;
      height: 20vh;
      background-position: center;
    }
  }
  .buttonpenawaran {
    margin-top: 25px;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
    font-style: normal;
    text-decoration: none;
    line-height: 1em;
    letter-spacing: 0px;
    fill: #ffffff;
    color: #ffffff;
    background-color: #467ff7;
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #467ff7;
    border-radius: 100px 100px 100px 100px;
    padding: 16px 55px 16px 55px;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
      background-color: white;
      color: black;
    }
  }
}
