.container-kavling {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 150px;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .judul1-kav {
    color: #88aab8;
    font-family: "Inter", Sans-serif;
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
    font-style: normal;
    text-decoration: none;
    // line-height: 24px;
    letter-spacing: 3px;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  .judul2-kav {
    margin-top: 10px;
    color: #05173d;
    font-family: "Inter", Sans-serif;
    font-size: 42px;
    font-weight: 500;
    text-transform: capitalize;
    font-style: normal;
    text-decoration: none;
    line-height: 44px;
    letter-spacing: -1px;
    @media (max-width: 768px) {
      text-align: center;
      margin-bottom: -40px;
    }
  }
  .container-cluster-card {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .cluster-card {
      background-color: white;
      margin-right: 10px;
      margin-left: 10px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
      border-radius: 10px 10px 10px 10px;
      width: 345px;
      justify-content: center;
      @media (max-width: 768px) {
        width: 95%;
        margin-top: 30px;
      }
      .cluster-image {
        width: 100%;
        height: auto;
        border-radius: 10px 10px 0px 0px;
        margin-bottom: -20px;
      }
      .cluster-card-title {
        font-family: "Roboto", Sans-serif;
        display: flex;
        width: 100%;
        flex-direction: row;
        padding: 35px 0 5px 0;
        .cluster-name {
          text-align: center;
          justify-content: center;
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 100%;

          .cluster-name-sub {
            font-size: 22px;
            font-weight: 600;
            color: #1c244b;
            font-family: "Poppins", Sans-serif;
            text-transform: capitalize;
            font-style: normal;
            text-decoration: none;
            line-height: 1.2em;
            letter-spacing: 0px;
            word-spacing: em;
          }
          .cluster-name-sub2 {
            font-size: 14px;
            font-weight: 400;
            color: #1c244b;
            font-family: "Poppins", Sans-serif;
            text-transform: capitalize;
            font-style: normal;
          }
        }
        .cluster-price {
          padding-bottom: 10px;
          justify-content: center;
          flex-direction: column;
          display: flex;
          align-items: center;
          background-color: #467ff7;
          border-radius: 10px 0px 0px 10px;
          text-align: center;
          color: #ffffff;
          width: 100%;
          .cluster-price-sub {
            font-size: 50px;
            font-weight: 600;
          }
          //.cluster-price-sub2 {
          //}
        }
      }
      .card-separator {
        height: 0.1px;
        margin-top: 15px;
      }
      .cluster-icon {
        padding: 15px 0px 15px 0;
        display: flex;
        font-size: 18px;
        justify-content: center;
        // span {
        // }
      }
      .cluster-button {
        padding-bottom: 15px;
        text-align: center;
        .cluster-whatsapp {
          font-family: "Poppins", Sans-serif;
          font-size: 16px;
          font-weight: 400;
          text-transform: capitalize;
          font-style: normal;
          text-decoration: none;
          line-height: 1em;
          letter-spacing: 0px;
          fill: #ffffff;
          color: #ffffff;
          background-color: #467ff7;
          border-style: solid;
          border-width: 1px 1px 1px 1px;
          border-color: #467ff7;
          border-radius: 15px 15px 15px 15px;
          padding: 16px 85px 16px 85px;
          cursor: pointer;
          transition: background-color 0.3s ease, color 0.3s ease;
          &:hover {
            background-color: white;
            color: black;
          }
        }
      }
    }
  }
}
