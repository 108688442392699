@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.grandwiss-container {
  font-family: "Poppins", sans-serif;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f3f5f8;

  .grandwiss-header {
    width: 100%;
    height: 50px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  }
  .grandwiss-carousel {
    width: 100%;
    .grandwiss-img {
      .slick-next {
        right: 25px;
      }
      .slick-prev {
        z-index: 1;
        left: 25px;
      }
      .slick-next::before,
      .slick-prev::before {
        font-family: "slick";
        font-size: 30px;
      }
    }
  }
}
