.container-vicente {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .card {
    width: 350px;
    background-color: white;
    margin-right: 5px;
    margin-left: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      margin-top: 50px;
      width: 95%;
      justify-content: center;
    }
    .card-image {
      border-radius: 10px 10px 0px 0px;
      width: 100%;
    }
    .slick-dots {
      bottom: 15px;
    }
    .slick-next {
      right: 25px;
    }
    .slick-prev {
      z-index: 1;
      left: 25px;
    }
    .slick-next::before,
    .slick-prev::before {
      font-family: "slick";
      font-size: 30px;
    }
    .content-card {
      display: flex;
      align-items: center;
      flex-direction: column;
      .card-title {
        font-family: "Poppins", sans-serif;
        font-size: 30px;
        font-weight: 600;
        margin-top: 6px;
      }
      .card-price {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 300;
        padding: 0px;
        .judul-pt {
          font-size: 16px;
          padding: 0px;
          font-weight: 500;
        }
        .ket-pt {
          font-size: 16px;
          padding: 0px;
          font-weight: 500;
        }

        .pricetotal {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 45px;
          font-weight: 500;
          padding: 0px;
          margin: -15px;
        }
      }
      .card-dp {
        background-color: #467ff7;
        color: white;
        font-family: "Poppins", sans-serif;
        width: 100%;
        font-size: 32px;
        font-weight: 800;
        padding: 5px 0px 5px 0;
        display: flex;
        justify-content: center;
      }
      .card-icon {
        color: #3c3c3c;
        font-size: 18px;
        padding: 15px 0px 15px 0;
        span {
          margin: 0px 5px 0px 5px;
        }
      }
      .card-button {
        padding: 10px 0px 15px 0;
        .button {
          font-family: "Poppins", Sans-serif;
          font-size: 16px;
          font-weight: 400;
          text-transform: capitalize;
          font-style: normal;
          text-decoration: none;
          line-height: 1em;
          letter-spacing: 0px;
          fill: #ffffff;
          color: #ffffff;
          background-color: #467ff7;
          border-style: solid;
          border-width: 1px 1px 1px 1px;
          border-color: #467ff7;
          border-radius: 15px 15px 15px 15px;
          padding: 16px 85px 16px 85px;
          cursor: pointer;
          transition: background-color 0.3s ease, color 0.3s ease;
          &:hover {
            background-color: white;
            color: black;
          }
        }
      }
    }
  }
}
